<template>
  <main>
    <div class="product-card">
      <div class="image-wrapper">
        <router-link
          v-if="routeName"
          :to="'/sh-retailer/list-of-products/' + product.id"
          :productStatus="importedProduct.importListStatus"
        >
          <img
            v-if="
              importedProduct.productImages &&
              importedProduct.productImages[0] &&
              importedProduct.productImages[0].thumbnailImage
            "
            :src="importedProduct.productImages[0].thumbnailImage"
            alt="Product"
            loading="lazy"
            class="product-image"
          />
          <img
            v-else
            loading="lazy"
            :src="require(`@/assets/img/no-image.png`)"
            alt="product-image"
            class="product-image"
          />
          <div class="alert_badge">
            <span
              v-if="importedProduct.stock <= 5 && importedProduct.stock >= 1"
              class="stock_alert ml-6"
            >
              Low stock
            </span>
            <span v-else-if="importedProduct.stock == 0" class="stock_low ml-6">
              out of stock
            </span>
          </div>
        </router-link>
        <router-link
          v-else
          :to="'/retailer/list-of-products/' + product.id"
          :productStatus="importedProduct.importListStatus"
        >
          <img
            v-if="
              importedProduct.productImages &&
              importedProduct.productImages[0] &&
              importedProduct.productImages[0].thumbnailImage
            "
            :src="importedProduct.productImages[0].thumbnailImage"
            alt="Product"
            loading="lazy"
            class="product-image"
          />
          <img
            v-else
            loading="lazy"
            :src="require(`@/assets/img/no-image.png`)"
            alt="product-image"
            class="product-image"
          />
          <div class="alert_badge">
            <span
              v-if="importedProduct.stock <= 5 && importedProduct.stock >= 1"
              class="stock_alert ml-6"
            >
              Low stock
            </span>
            <span v-else-if="importedProduct.stock == 0" class="stock_low ml-6">
              out of stock
            </span>
          </div>
        </router-link>
      </div>

      <div class="card-body">
        <router-link
          v-if="routeName"
          :to="'/sh-retailer/list-of-products/' + importedProduct.id"
          :productStatus="importedProduct.importListStatus"
        >
          <h2 class="card-title" :title="importedProduct.title">
            {{ importedProduct.title }}
          </h2>
        </router-link>
        <router-link
          v-else
          :to="'/retailer/list-of-products/' + importedProduct.id"
          :productStatus="importedProduct.importListStatus"
        >
          <h2 class="card-title" :title="importedProduct.title">
            {{ importedProduct.title }}
          </h2>
        </router-link>
        <div class="sallerName">
          <div>
            <div
              v-if="$route.path.includes('/retailer/home')"
              class="vertical-devider"
            >
              <router-link :to="'/retailer/supplierProfile/' + product.sellerID">
                <span class="amount-in-stock-seller"
                  >By:
                  <span
                    class="seller text-decoration-underline"
                    :title="importedProduct.sellerName"
                  >
                    {{ importedProduct.sellerName }} </span
                  >|
                </span></router-link
              >
              <span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/sh-retailer/list-of-products')"
              class="vertical-devider"
            >
              <router-link :to="'./supplierProfile/' + product.sellerID"
                ><span class="amount-in-stock-seller"
                  >By:
                  <span class="seller">{{ importedProduct.sellerName }}</span
                  >|</span
                ></router-link
              >
              <span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/retailer/list-of-products')"
              class="vertical-devider"
            >
              <router-link :to="'/retailer/supplierProfile/' + product.sellerID"
                ><span class="amount-in-stock-seller"
                  >By:
                  <span class="seller">{{ importedProduct.sellerName }}</span
                  >|</span
                ></router-link
              >
              <span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/sh-retailer/internal-list')"
              class="vertical-devider"
            >
              <router-link :to="'./supplierProfile/' + product.sellerID"
                ><span class="amount-in-stock-seller"
                  >By:
                  <span class="seller">{{ importedProduct.sellerName }}</span
                  >|</span
                ></router-link
              >
              <span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/retailer/internal-list')"
              class="vertical-devider"
            >
              <router-link :to="'/retailer/supplierProfile/' + product.sellerID"
                ><span class="amount-in-stock-seller"
                  >By:
                  <span class="seller">{{ importedProduct.sellerName }}</span
                  >|</span
                ></router-link
              >
              <span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/sh-retailer/supplierProfile')"
              class="vertical-devider"
            >
              <span class="amount-in-stock-seller"
                >By:
                <span class="Supplierseller">{{
                  importedProduct.sellerName
                }}</span
                >|</span
              ><span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
            <div
              v-if="$route.path.includes('/retailer/supplierProfile')"
              class="vertical-devider"
            >
              <span class="amount-in-stock-seller"
                >By:
                <span class="Supplierseller">{{
                  importedProduct.sellerName
                }}</span
                >|</span
              ><span class="amount-in-stock"
                >{{ importedProduct.stock }} in Stock
              </span>
            </div>
          </div>
        </div>
        <div class="price-box">
          <span
            v-if="
              importedProduct.minPrice &&
              importedProduct.maxPrice &&
              importedProduct.minPrice !== importedProduct.maxPrice
            "
            class="item-cost"
            >Cost:
            {{
              toCurrency(
                importedProduct.minPrice,
                importedProduct.shippings[0].currency
              )
            }}
            -
            {{
              toCurrency(
                importedProduct.maxPrice,
                importedProduct.shippings[0].currency
              )
            }}</span
          >
          <span
            v-else-if="
              importedProduct.minPrice &&
              (!importedProduct.maxPrice ||
                importedProduct.minPrice === importedProduct.maxPrice)
            "
            class="item-cost"
            >Cost:
            {{
              toCurrency(
                importedProduct.minPrice,
                importedProduct.shippings[0].currency
              )
            }}
          </span>
          <span v-else style="height: 22.5px" class="item-cost"> </span>

          <span
            v-if="
              importedProduct.minRetailPrice &&
              importedProduct.maxRetailPrice &&
              importedProduct.minRetailPrice !== importedProduct.maxRetailPrice
            "
            class="retail-price"
            >Suggested selling Price:
            {{
              toCurrency(
                importedProduct.minRetailPrice,
                importedProduct.shippings[0].currency
              )
            }}
            -
            {{
              toCurrency(
                importedProduct.maxRetailPrice,
                importedProduct.shippings[0].currency
              )
            }}</span
          >
          <span
            v-else-if="
              importedProduct.minRetailPrice &&
              (!importedProduct.maxRetailPrice ||
                importedProduct.minRetailPrice ===
                  importedProduct.maxRetailPrice)
            "
            class="retail-price"
            >Suggested selling Price:
            {{
              toCurrency(
                importedProduct.minRetailPrice,
                importedProduct.shippings[0].currency
              )
            }}
          </span>
          <span v-else style="height: 19.5px" class="retail-price"> </span>
          <div v-if="product.tags.length" class="tags-type">
            <div
              :class="[`tag ${colors[index % 10]}`]"
              v-for="(tag, index) in product.tags"
              v-show="index < number"
              @click="isShow = !isShow"
              :key="index"
            >
              {{ tag }}
            </div>
            <div
              v-if="product.tags.length < 10 && product.tags.length > 3"
              :style="{
                backgroundColor: active ? 'white' : '#BCE3E6',
                color: active ? 'white' : '#042B2E',
                border: active ? 'white' : '  1px $grey-100',
              }"
              class="tag-button"
            >
              <span class="tag-number"
                >+{{ product.tags.length - number }}</span
              >
            </div>
          </div>
          <div v-else class="tags-type" style="height: 25px"></div>
        </div>
        <div class="shipping-wrapper">
          <div class="shipping-icon-wrapper d-flex align-items-center">
            <img
              loading="lazy"
              src="@/assets/img/shipping.png"
              alt="Shipping"
              class="shipping-icon mt-0"
            />
            <span class="shipping-placeholder">Shipping</span>
          </div>
          <v-menu top left rounded="lg" :close-on-click="true">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="shipping-time"
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
              >
                <img
                  loading="lazy"
                  v-if="product.shippings && product.shippings.length"
                  :src="
                    require(`@/assets/img/flags/${product.country?.iso2}.svg`)
                  "
                  alt="flag"
                  class="flag"
                />
                <span
                  v-if="
                    importedProduct.shippings[0].min_price &&
                    importedProduct.shippings[0].max_price &&
                    importedProduct.shippings[0].min_price !==
                      importedProduct.shippings[0].max_price
                  "
                  class="item"
                >
                  {{
                    toCurrency(
                      importedProduct.shippings[0].min_price,
                      importedProduct.shippings[0].currency
                    )
                  }}
                  -
                  {{
                    toCurrency(
                      importedProduct.shippings[0].max_price,
                      importedProduct.shippings[0].currency
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    importedProduct.shippings[0].min_price &&
                    !importedProduct.shippings[0].max_price
                  "
                  class="item"
                >
                  {{
                    toCurrency(
                      importedProduct.shippings[0].min_price,
                      importedProduct.shippings[0].currency
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    !importedProduct.shippings[0].min_price &&
                    importedProduct.shippings[0].max_price
                  "
                  class="item"
                >
                  Up to
                  {{
                    toCurrency(
                      getMaxPrice,
                      importedProduct.shippings[0].currency
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    importedProduct.shippings[0].min_price &&
                    importedProduct.shippings[0].max_price &&
                    importedProduct.shippings[0].min_price ===
                      importedProduct.shippings[0].max_price
                  "
                  class="item"
                >
                  {{
                    toCurrency(
                      getMaxPrice,
                      importedProduct.shippings[0].currency
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    !importedProduct.shippings[0].min_price &&
                    !importedProduct.shippings[0].max_price
                  "
                  class="item"
                >
                  Free
                </span>
                <span class="mr-2">|</span>
                <span class="item text-decoration-none">
                  {{ importedProduct.shippings[0].min_delivery_date }}
                  -
                  {{ importedProduct.shippings[0].max_delivery_date }}
                  days</span
                >
              </div>
            </template>
            <div
              class="d-flex flex-column align-center pa-6 shipping_detail_wrapper"
            >
              <div class="d-flex flex-column align-center justify-center">
                <div class="align-self-start">
                  <h5 class="mb-2 shipping_table_title">Shipping Details</h5>
                </div>
                <div>
                  <table class="shipping_table_detail" style="width:432px:">
                    <thead class="theader">
                      <tr>
                        <th>Destination</th>
                        <th>Cost</th>
                        <th>Delivary days</th>
                      </tr>
                    </thead>
                    <tbody class="tbody">
                      <tr
                        class="each-row"
                        v-for="(shipping, indexShipping) in product.shippings"
                        :key="indexShipping"
                      >
                        <td>{{ shipping.country }}</td>

                        <td
                          v-if="
                            shipping.min_price &&
                            shipping.max_price &&
                            shipping.min_price !== shipping.max_price
                          "
                          class="item1"
                        >
                          {{
                            toCurrency(shipping.min_price, shipping.currency)
                          }}
                          -
                          {{
                            toCurrency(shipping.max_price, shipping.currency)
                          }}
                        </td>
                        <td
                          v-else-if="shipping.min_price && !shipping.max_price"
                          class="item1"
                        >
                          {{
                            toCurrency(shipping.min_price, shipping.currency)
                          }}
                        </td>
                        <td
                          v-else-if="!shipping.min_price && shipping.max_price"
                          class="item1"
                        >
                          {{
                            toCurrency(shipping.max_price, shipping.currency)
                          }}
                        </td>
                        <td
                          v-else-if="shipping.min_price && shipping.max_price"
                          class="item1"
                        >
                          {{
                            toCurrency(shipping.max_price, shipping.currency)
                          }}
                        </td>
                        <td
                          v-else-if="!shipping.min_price && !shipping.max_price"
                          class="item1"
                        >
                          Free
                        </td>

                        <td>
                          {{ shipping.min_delivery_date }}
                          -
                          {{ shipping.max_delivery_date }}
                          days
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </v-menu>
        </div>
        <div class="button-wrapper d-flex justify-center mt-3">
          <div class="w-100">
            <BaseBtn
              :style="{ borderRadius: '5px' }"
              class="w-100"
              v-if="
                importedProduct.stock === 0 &&
                importedProduct.importListStatus !== 'imported' &&
                importedProduct.importListStatus !== 'created'
              "
              @clicked="notifyMe(id)"
            >
              Notify me
            </BaseBtn>
            <BaseBtn
              v-else-if="
                importedProduct.importListStatus === 'not_imported' ||
                importedProduct.importListStatus === 'no_retailer'
              "
              class="btn_green"
              @clicked="addToImportList(importedProduct.id)"
            >
              Add to Import List
            </BaseBtn>

            <BaseBtn
              v-else-if="
                importedProduct.importListStatus === 'imported' ||
                importedProduct.importListStatus === 'backed'
              "
              @clicked="backToImportList(product.id)"
              class="remove-from-shop-btn w-100"
            >
              Remove from my shop
            </BaseBtn>

            <BaseBtn
              class="w-100 remove-import-List-btn"
              v-else-if="importedProduct.importListStatus === 'created'"
              @clicked="RemoveFromImportList(importedProduct.importListID)"
            >
              Remove from Import List
            </BaseBtn>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// Services
import RetailerProducts from "@/services/RetailerProducts";
import { mapGetters, mapActions } from "vuex";
import Notification from "@/components/bases/Notification.vue";
import BaseBtn from "@/components/bases/BaseBtn.vue";

export default {
  name: "ProductCard",
  props: ["product", "freeShipping"],
  components: {
    Notification,
    BaseBtn,
    RetailerProducts,
  },
  data: () => ({
    isHovering: false,
    importErrorMessage: "",
    importedProduct: {},
    showImportError: false,
    showImportSuccess: false,
    showAlertError: false,
    showAlertSuccess: false,
    number: 3,
    plan: "",

    active: false,
    tags: [],
    colors: ["Red", "Green", "Yellow", "Blue", "Bluesky", "Gray"],
  }),
  created() {
    this.importedProduct = JSON.parse(JSON.stringify(this.product));
  },

  computed: {
    routeName() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      return url.pathname.includes("/sh-retailer");
    },
    ...mapGetters({
      alertError: "alert/alertError",
      newAlert: "alert/newAlert",
    }),
    defaultCurrency() {
      return this.product.shippings[0].currency
        ? this.product.shippings[0].currency
        : "EUR";
    },
    getMaxPrice() {
      return Math.min(
        ...this.importedProduct.shippings.map((shipping) => shipping.min_price)
      );
    },
  },

  methods: {
    ...mapActions([
      "retailer/importProductToList",
      "retailer/removeImportedList",
      "alert/clear",
      "alert/newAlert",
    ]),
    toCurrency: function (price, currency) {
      if (!currency) {
        currency = this.defaultCurrency;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: currency,
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },

    async addToImportList(id) {
      try {
        if (this.importedProduct.importListStatus === "no_retailer") {
          this.showAlertError = true;
          this["alert/newAlert"]({
            type: "warning",
            message: "Please connect a store first!",
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        } else {
          const importListID = await this["retailer/importProductToList"](id);

          if (importListID) {
            this.importedProduct.importListID = importListID;
            this.showImportSuccess = true;
            this.showAlertSuccess = true;
            this.showImportError = false;
            this.importedProduct.importListStatus = "created";
          } else {
            this.showImportError = true;
            this.showAlertError = true;
            this.showImportSuccess = false;
          }
        }
      } catch (e) {
        console.log(e);
        this["alert/newAlert"]({
          type: "error",
          message: e.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
      }
    },
    backToImportList(productID) {
      const agreed = window.confirm(
        "Are you sure you want to go back to import list?"
      );
      //this.removingProduct = false;
      if (agreed)
        RetailerProducts.backToImportList(productID)
          .then((response) => {
            console.log(response);
            this["alert/newAlert"]({
              type: "success",
              message: "Product backed to import list successfully",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);

            this.backingImportlist = false;

            this.importedProduct.importListStatus = "created";
          })
          .catch((e) => {
            console.log(e);
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          });
    },
    async RemoveFromImportList(id) {
      const response = await this["retailer/removeImportedList"](id);

      if (response) {
        this.showImportSuccess = true;
        this.showAlertSuccess = true;
        this.showImportError = false;
        this.importedProduct.importListStatus = "not_imported";
      } else {
        this.showImportError = true;
        this.showAlertError = true;
        this.showImportSuccess = false;
      }
    },
    async notifyMe(id) {
      this.showAlertError = true;
      this["alert/newAlert"]({
        type: "error",
        message: "we will let you when it becomes available",
      });
      setTimeout(async () => {
        this.showAlertError = false;
        this["alert/clear"]();
      }, 3000);

      // clear the alert after 2 seconds
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.shipping_detail_wrapper {
  font-size: 1rem;
  background: white;
  .shipping_table_title {
    font-weight: bold;
    font-size: $font_size_base + 0.4rem;
  }
  .shipping_table_origin {
    font-weight: bold;
    font-size: $font_size_base + 0.3rem;
  }
  .shipping_table_title {
    font-weight: bold;
    font-size: $font_size_base + 0.4rem;
  }
  .shipping_table_detail {
    // width: 90%;

    td,
    tr,
    th {
      padding: 5px;
    }
    td {
      font-weight: 700;
      font-size: $font_size_base + 0.1rem;
    }
    th {
      font-weight: bold;
      font-size: $font_size_base + 0.3rem;
    }
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}

.stock_alert {
  background: $yellow-400;
  color: white;
  font-size: $font_size_base + 0.2rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
}
.stock_low {
  background: $red-500;
  color: white;
  font-size: $font_size_base + 0.2rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
}
.alert_badge {
  position: absolute;
  bottom: 10px !important;
  left: -16px !important;
}

.tag-number {
  width: 14px;
  height: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #042b2e;
}
.tags-type {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 16px;

  .tag {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    height: 25px;
    padding: 6px 8px;
    color: #202223;
    &.Green {
      background: #aee9d1;
    }
    &.Yellow {
      background: #ffeeba;
    }
    &.Bluesky {
      background: #a4e8f2;
    }
    &.Red {
      background: #fed3d1;
    }
    &.Blue {
      background: #bce3e6;
    }
    &.Gray {
      background: #e4e7ec;
    }
  }
}
.item {
  margin-right: 8px;
  color: #202223;
  text-decoration: underline;
}
.back {
  margin-right: 11.33px;
}
.Free_shipping {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #008f4a;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
</style>
