<template>
  <main class="supplier-profile-section">
    <retailerNavigation v-if="!$route.path.includes('/sh-retailer')" />
    <v-main
      :class="{
        'retailer-main-container-supplier-profile':
          !$route.path.includes('/sh-retailer'),
      }"
    >
      <div class="page-wrapper">
        <div class="seller1">
          <router-link
            v-if="$route.path.includes('/sh-retailer')"
            to="/sh-retailer/list-of-products"
            ><img
              class="avatar"
              src="@/assets/img/back-button.png"
              alt="view"
              style="width: 44px"
          /></router-link>
          <router-link v-else to="/retailer/list-of-products"
            ><img
              class="avatar"
              src="@/assets/img/back-button.png"
              alt="view"
              style="width: 44px"
          /></router-link>
          <span class="seller ml-4">{{ seller.brand_name }}</span>
          <div class="tags-type tags-type-header">
            <div class="tag Green">Vegan</div>
            <div class="tag Yellow">Sustainable</div>
            <div class="tag Bluesky">Organic</div>
            <div class="tag Red">Eco-friendly</div>
            <div class="tag Blue">Zero-wasted</div>
            <div class="tag Gray">cruelty free</div>
          </div>
        </div>
        <div class="description">
          {{ seller.brandStory }}
        </div>
        <div class="box">
          <div class="ship">
            <span class="shipFrom"
              >Ship from:<img
                :src="`@/assets/img/flags/${seller.shipping_from}.svg`"
                alt="flag"
                class="flag"
            /></span>
            <span
              v-if="seller.min_processing_time === seller.max_processing_time"
              class="shipFrom"
              >Average processing time:<span class="ship1"
                >{{ seller.min_processing_time }} Day(s)</span
              ></span
            >
            <span
              v-else-if="
                seller.min_processing_time !== seller.max_processing_time
              "
              class="shipFrom"
              >Average processing time:
              <span class="ship1"
                >{{ seller.min_processing_time }}-{{
                  seller.max_processing_time
                }}
                Day(s)</span
              ></span
            >
            <div class="">
              <span class="shipcost"
                >Shipping cost:<span class="ship1"
                  >Starts at {{ toCurrency(seller.min_shipping_cost) }}</span
                ></span
              >

              <br />
            </div>
          </div>
          <div class="query_container mb-4">
            <SearchBtnV3
              :placeholder="'Search'"
              :searchQuery="ProductsPagination.q"
              :maxWidth="'992px'"
              @search="Search($event)"
              @removeText="removeText()"
            ></SearchBtnV3>

            <SelectDropSortV2
              :icon="'sort'"
              :sortList="['Suggested', 'Latest', 'Trending']"
              @sortby="Search(null, $event.sort_by)"
              class="ml-6"
              :maxWidth="'150px'"
            />
          </div>
          <SmartChips class="mb-5" :chips="chips" @removeChips="removeChips" />
        </div>
        <div class="products-list-wrapper">
          <div v-for="product in products" :key="product.id">
            <ProductCard :product="product" :freeShipping="freeShipping" />
          </div>
        </div>
        <v-row v-if="page_size > 1">
          <v-col cols="12" sm="12" md="12">
            <v-pagination
              v-model="page"
              :length="page_size"
              :total-visible="7"
              @input="next"
              color="#008f4a"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductCard from "@/components/ProductCard";
import retailerNavigation from "@/components/navigation/retailer.vue";
  
import SelectDropSortV2 from "@/components/bases/SelectDropSortV2.vue";
import BaseSelectDrop from "@/components/bases/BaseSelectDrop.vue";
import SearchBtnV3 from "@/components/bases/SearchBtnV3.vue";
import RetailerProducts from "@/services/RetailerProducts";
import SellerProducts from "@/services/SellerProducts";
 

import SmartChips from "@/components/retailer/chips/SmartChips.vue";
export default {
  components: {
    SellerProducts,
    ProductCard,
    retailerNavigation,
  
    SearchBtnV3,
    SelectDropSortV2,
    BaseSelectDrop,
 
    SmartChips,
  },
  props: [],
  data() {
    return {
      page: 1,
      page_size: 0,
      products: [],
      seller: {},
      ProductsPagination: {
        sort: null,
        q: null,
      },
      sellerID: [],
      freeShipping: false,
      count: 20,
      plan: "",
      filters: [],
      chips: [],
      tags: [],
      colors: [
        "Red",
        "Green",
        "Yellow",
        "Blue",
        "Bluesky",
        "Gray",
        "Red",
        "Green",
        "Yellow",
        "Blue",
        "Bluesky",
        "Gray",
      ],
    };
  },

  computed: {
    urlRoute() {
      return { ...this.$route.query };
    },
    ...mapGetters({
      listOfProductsPagination: "retailer/listOfProductsPagination",
      listOfSellers: "retailer/listOfSellers",
    }),
    defaultCurrency() {
      return this.seller && this.seller.currency !== ""
        ? this.seller.currency
        : "EUR";
    },
  },
  mounted() {
    this.getFilters();
    this.getSellers(this.$route.params.id);
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      try{
        var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
      }catch(e){
        console.log("Error toCurrency ", e);
        return value;
      }
     
    },
  },
  watch: {
    async urlRoute(newRoute) {
      this.generateChips();
    },
  },
  methods: {
    ...mapActions([
      "retailer/setListOfProductsPagination",
      "retailer/getListOfSellers",
    ]),

    getProducts() {
      const filterByCategory =
        this.currentSort === ("newest" || "latest") ? null : this.currentSort;
      let page = {
        count: this.count,
        offset: this.count * (this.page - 1),
        sort: "newest",
        seller_id: this.$route.params.id,
      };

      RetailerProducts.getProducts(page)
        .then((response) => {
          this.page = this.page;
          if (response.data) {
            this.products = response.data.products;
            this.freeShipping = response.data.freeShipping;
            this.totalProductCount = response.data.totalCount;
            this.page_size = Math.ceil(response.data.totalCount / this.count);
            if (this.products.length == 0) this.hasNoProduct = true;
            else this.hasNoProduct = false;
            window.scrollTo(0, 0);
          } else {
            this.hasNoProduct = true;
          }
        })
        .catch((e) => {
          console.log(e.message);
          this.noConnectedShop = true;
        });
    },
    next(page) {
      this.getProducts(page);
    },
    getSellers(sellerID) {
      SellerProducts.getSellers(sellerID)
        .then((response) => {
          this.seller = response.data.seller_info;
          this.getProducts();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    removeText() {
      if (this.ProductsPagination.q) {
        this.removeChips({ type: "q", q: this.ProductsPagination.q });
        this.ProductsPagination.q = null;
      }
    },

    removeChips(chip) {
      console.log(chip);

      let urlQueries = Object.assign({}, this.$route.query);

      if (chip.type === "q") {
        delete urlQueries[chip.type];
        this.ProductsPagination.q = null;
        if (this.ProductsPagination.sort) {
          this.Search(null, this.ProductsPagination.sort);
        } else {
          this.Search(null, null);
        }
      }

      if (chip.type === "sort") {
        delete urlQueries[chip.type];
        this.ProductsPagination.sort = null;
        if (this.ProductsPagination.q) {
          this.Search(this.ProductsPagination.q);
        } else {
          this.Search();
        }
      }

      this.$router.replace({
        query: urlQueries,
      });
    },
    getFilters() {
      RetailerProducts.getFilters()
        .then((response) => {
          this.filters = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async generateChips() {
      let newchips = [];
      if (this.$route.query.q) {
        newchips.push({
          type: "q",
          value: this.$route.query.q,
        });
      }

      if (this.$route.query.sort) {
        newchips.push({
          type: "sort",
          value: this.$route.query.sort,
        });
      }

      this.chips = newchips;
    },
    async Search(event, sort = null) {
      sort && (this.ProductsPagination.sort = sort);
      event && (this.ProductsPagination.q = event);
      let pag = {
        count: this.count,
        offset: 0,
        sort: this.ProductsPagination.sort,
        search: this.ProductsPagination.q || null,
        category: this.listOfProductsPagination.category || "all",
        seller_id: this.$route.params.id,
      };

      RetailerProducts.getProducts(pag)
        .then((response) => {
          this.products = response.data.products;
          this.freeShipping = response.data.freeShipping;
          this.totalProductCount = response.data.totalCount;
          this.page_size = Math.ceil(response.data.totalCount / this.count);
          this.page = 1;
          if (this.products.length == 0) this.hasNoProduct = true;
          else this.hasNoProduct = false;
          this.$router
            .push({
              query: {
                ...(this.ProductsPagination.sort
                  ? { sort: this.ProductsPagination.sort }
                  : {}),
                ...(this.ProductsPagination.q
                  ? { q: this.ProductsPagination.q }
                  : {}),
              },
            })
            .catch(() => {});
        })
        .catch((e) => {
          console.log(e);
        });
    },
    toCurrency: function (price) {
      try{
        var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: this.defaultCurrency,
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;

      }catch(e){
        console.log("Error toCurrency 1", e);
        return price;
      }
      
    },
  },
};
</script>

<style lang="scss">
.supplier-profile-section {
  .chips__item__text {
    font-size: 12px;
    padding: 3px;
  }
  .v-pagination {
    justify-content: center !important;
    padding-bottom: 21px;
  }
  .retailer-main-container-supplier-profile {
    @media screen and (min-width: 1621px) {
      width: calc(100% - 263px) !important;
      margin-left: auto;
    }
    @media screen and (min-width: 300px) {
      width: calc(100% - 85px);
      margin-left: auto;
      margin-right: 5px;
    }
  }
  @import "@/assets/scss/variables2.scss";
  .products-list-wrapper {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 355px);
    grid-gap: 24px;
    justify-content: start;
    align-items: stretch;
    padding-bottom: 16px;
  }

  .seller {
    width: 500px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $grey-900;
    margin-top: 86px;
    padding-bottom: 44px;
  }
  .seller1 {
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $grey-900;
    margin-top: 47px;
    padding-bottom: 44px;
  }
  .tags-type-header {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: -34px;
  }
  .description {
    color: #6d7175;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
    padding-left: 65px;
  }
  .ship {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1d2939;
    border-bottom: 1px solid #e1e3e5;
    border-top: 1px solid #e1e3e5;

    margin-bottom: 16px;
    padding: 21px;
    display: flex;
    padding-left: 60px;
    margin-top: 21px;
  }
  .ship1 {
    margin-left: 2px;
    color: #202223;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .shipFrom {
    margin-right: 290px;
    color: #202223;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .Free_shipping {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #008f4a;

    cursor: pointer;
  }
  .shipcost {
    margin-top: 34px;
    margin-bottom: 16px;
    color: #202223;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .query_container {
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: space-between;
  }
  .statusIcon {
    position: absolute;
    width: 15px;
    margin-top: 16px;
    margin-left: 8px;
    border-right: 24px;
  }
  .flag {
    margin-left: 11px;
  }
  .importlist_banner {
    margin-top: 60px;
    width: 100%;
  }
}
</style>
