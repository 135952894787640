<template>
  <div
    class="drop_down_container"
    ref="dropdown"
    :style="{ width: maxWidth, minWidth: minWidth }"
  >
    <div v-if="!multiple">
      <div class="input_container">
        <div class="input_label" v-if="label">{{ label }}</div>

        <input
          v-if="Trim"
          class="input_filter"
          type="text"
          id="sortBy"
          name="sortBy"
          :placeholder="placeholder"
          :value="sortBy || sortByObject[TextField] | truncate(Trim, '...')"
          @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
          readonly
          :disabled="disabled"
        />

        <input
          v-else
          class="input_filter"
          type="text"
          id="sortBy"
          name="sortBy"
          :placeholder="placeholder"
          :value="sortBy || sortByObject[TextField]"
          @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
          readonly
          :disabled="disabled"
        />
        <img
          @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
          src="@/assets/img/sort_minor.png"
          style="cursor: pointer"
        />
      </div>
      <div
        @click="handleDropdownClick"
        v-if="showSortBy && sortBy"
        class="drop_options"
      >
        <div v-if="Trim">
          <div v-for="(sortKey, index) in sortList" :key="index">
            <div
              class="drop_option d-flex align-center"
              :class="sortBy === sortKey ? 'drop_option_active' : ''"
              @click="SortBy(sortKey)"
            >
              {{ sortKey | truncate(trim, "...") }}
              <span class="material-icons ml-auto" v-if="sortBy === sortKey">
                check
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(sortKey, index) in sortList" :key="index">
            <div
              class="drop_option d-flex align-center"
              :class="sortBy === sortKey ? 'drop_option_active' : ''"
              @click="SortBy(sortKey)"
            >
              {{ sortKey }}
              <span class="material-icons ml-auto" v-if="sortBy === sortKey">
                check
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="showSortBy && sortByObject"
        class="drop_options"
        @click="handleDropdownClick"
      >
        <div v-if="Trim">
          <div v-for="(sortKey, index) in sortList" :key="index">
            <div
              class="drop_option d-flex align-center"
              :class="
                sortByObject[TextField] === sortKey[TextField]
                  ? 'drop_option_active'
                  : ''
              "
              @click="SortByObject(sortKey)"
            >
              {{ sortKey[TextField] | truncate(Trim, "...") }}
              <span
                class="material-icons ml-auto"
                v-if="sortByObject[TextField] === sortKey[TextField]"
              >
                check
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(sortKey, index) in sortList" :key="index">
            <div
              class="drop_option d-flex align-center"
              :class="
                sortByObject[TextField] === sortKey[TextField]
                  ? 'drop_option_active'
                  : ''
              "
              @click="SortByObject(sortKey)"
            >
              {{ sortKey[TextField] }}
              <span
                class="material-icons ml-auto"
                v-if="sortByObject[TextField] === sortKey[TextField]"
              >
                check
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="multiple">
      <div class="input_container">
        <div class="input_label" v-if="label">{{ label }}</div>
        <input
          v-if="multiSelectedObject && multiSelectedObject.length == 0"
          class="input_filter"
          type="text"
          id="sortBy"
          name="sortBy"
          :placeholder="placeholder"
          @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
          readonly
          :disabled="disabled"
        />

        <input
          v-else
          class="input_filter"
          type="text"
          id="sortBy"
          name="sortBy"
          :value="`Selected: ${
            multiSelectedValue.length || multiSelectedObject.length
          }`"
          @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
          readonly
          :disabled="disabled"
        />
        <span
          class="material-icons drop_icon"
          :class="{ has_label: label, active: showSortBy }"
          readonly
        >
          expand_more
        </span>
      </div>
      <div
        @click="handleDropdownClick"
        v-if="showSortBy && sortBy"
        class="drop_options"
      >
        <div v-for="(sortKey, index) in sortList" :key="index">
          <div
            v-if="Trim"
            class="drop_option d-flex align-center"
            :class="
              multiSelectedValue.includes(sortKey) ? 'drop_option_active' : ''
            "
            @click="MultiSelectValue(sortKey)"
          >
            {{ sortKey | truncate(Trim, "...") }}
            <span
              class="material-icons ml-auto"
              v-if="multiSelectedValue.includes(sortKey)"
            >
              check
            </span>
          </div>
          <div
            v-else
            class="drop_option d-flex align-center"
            :class="
              multiSelectedValue.includes(sortKey) ? 'drop_option_active' : ''
            "
            @click="MultiSelectValue(sortKey)"
          >
            {{ sortKey }}
            <span
              class="material-icons ml-auto"
              v-if="multiSelectedValue.includes(sortKey)"
            >
              check
            </span>
          </div>
        </div>
      </div>
      <div
        v-else-if="showSortBy && sortByObject"
        @click="handleDropdownClick"
        class="drop_options"
      >
        <div v-for="(sortKey, index) in sortList" :key="index">
          <div
            v-if="Trim"
            class="drop_option d-flex align-center"
            :class="
              multiSelectedObject.some(
                (e) => e[TextField] === sortKey[TextField]
              )
                ? 'drop_option_active'
                : ''
            "
            @click="MultiSelectObject(sortKey)"
          >
            {{ sortKey[TextField] | truncate(Trim, "...") }}
            <span
              class="material-icons ml-auto"
              v-if="
                multiSelectedObject.some(
                  (e) => e[TextField] === sortKey[TextField]
                )
              "
            >
              check
            </span>
          </div>
          <div
            v-else
            class="drop_option d-flex align-center"
            :class="
              multiSelectedObject.some(
                (e) => e[TextField] === sortKey[TextField]
              )
                ? 'drop_option_active'
                : ''
            "
            @click="MultiSelectObject(sortKey)"
          >
            {{ sortKey[TextField] }}
            <span
              class="material-icons ml-auto"
              v-if="
                multiSelectedObject.some(
                  (e) => e[TextField] === sortKey[TextField]
                )
              "
            >
              check
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sortList: { type: Array, required: false },
    defaultSelected: { type: Number, required: false },
    maxAvailableChoice: { type: Number, required: false },
    defaultMultipleSelected: { type: Array, required: false },
    icon: { type: String, required: false },
    disabled: { type: Boolean, default: false, required: false },
    placeholder: { type: String },
    firstSelectedId: { type: Number, required: false },
    maxWidth: { type: String },
    minWidth: { type: String },
    label: { type: String },
    multiple: { type: Boolean, default: false, required: false },
    TextField: { type: String, required: false },
    TextValue: { type: String, required: false },
    Trim: { type: Number, required: false, default: 0 },
  },
  data() {
    return {
      sortBy: "",
      sortByObject: {},
      showSortBy: false,
      sort_by_arrow: false,
      multiSelectedValue: [],
      multiSelectedObject: [],
    };
  },
  created() {
    if (typeof this.sortList[0] === "object") {
      this.sortBy = null;
      if (this.TextValue) {
        if (!this.multiple) {
          const found = this.sortList.find(
            (list) => list[this.TextValue] == this.defaultSelected
          );
          if (found) this.sortByObject = found;
        } else {
          if (this.defaultMultipleSelected) {
            const dms = JSON.parse(
              JSON.stringify(this.defaultMultipleSelected)
            );
            const sL = JSON.parse(JSON.stringify(this.sortList));
            const found = [this.TextField, this.TextValue];

            const result = sL
              .filter(function (o1) {
                // filter out (!) items in dms
                return dms.some(function (o2) {
                  return o1.id === o2.id; // assumes unique id
                });
              })
              .map(function (o) {
                // use reduce to make objects with only the required properties
                // and map to apply this to the filtered array as a whole
                return found.reduce(function (newo, name) {
                  newo[name] = o[name];
                  return newo;
                }, {});
              });

            if (result.length) {
              this.sortByObject = result;
              this.multiSelectedObject = result;
            } else this.sortByObject = this.sortList;
          }
        }
      } else this.sortByObject = this.sortList;
    } else {
      this.sortByObject = null;
      this.sortBy = this.sortList[0];
    }
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
  methods: {
    closeDropdown(event) {
      // Check if the click event target is outside of the dropdown
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.showSortBy = false;
      }
    },
    handleDropdownClick(event) {
      // Prevent the click inside the dropdown from propagating and closing it immediately
      event.stopPropagation();
    },
    Trimm(text) {
      if (this.Trim) {
        return text.substring(0, this.Trim) + "...";
      }
    },
    SortBy(value) {
      if (value == this.sortBy) this.sortBy = "";
      else this.sortBy = value;
      this.$emit("sortby", {
        sort_by: value,
      });
      this.showSortBy = !this.showSortBy;
      this.sort_by_arrow = !this.sort_by_arrow;
    },

    SortByObject(value) {
      if (value == this.sortByObject) this.sortByObject = {};
      else this.sortByObject = value;
      this.$emit("sortbyobject", {
        sort_by_object: value,
      });
      this.showSortBy = !this.showSortBy;
      this.sort_by_arrow = !this.sort_by_arrow;
    },
    MultiSelectValue(value) {
      if (
        value === "All" &&
        this.sortList.length !== this.multiSelectedValue.length
      ) {
        this.multiSelectedValue = this.sortList;
        this.$emit("multiselected", {
          multiSelectedValue: this.multiSelectedValue,
        });
        return;
      } else if (
        value === "All" &&
        this.sortList.length === this.multiSelectedValue.length
      ) {
        this.multiSelectedValue = [];
        this.$emit("multiselected", {
          multiSelectedValue: this.multiSelectedValue,
        });
        return;
      } else if (value !== "All" && this.multiSelectedValue.includes("All")) {
        this.multiSelectedValue = this.multiSelectedValue.filter(
          (item) => item !== value
        );

        if (this.multiSelectedValue.length == 1) {
          this.multiSelectedValue = [];
          this.$emit("multiselected", {
            multiSelectedValue: this.multiSelectedValue,
          });
          return;
        }
        this.$emit("multiselected", {
          multiSelectedValue: this.multiSelectedValue,
        });
        return;
      }

      if (this.multiSelectedValue.includes(value)) {
        this.multiSelectedValue = this.multiSelectedValue.filter(
          (item) => item !== value
        );
        this.$emit("multiselected", {
          multiSelectedValue: this.multiSelectedValue,
        });
      } else {
        this.multiSelectedValue.push(value);
        if (
          value[this.TextField] !== "All" &&
          this.multiSelectedValue.length === this.sortList.length - 1
        )
          this.multiSelectedValue = this.sortList;
      }
      this.$emit("multiselected", {
        multiSelectedValue: this.multiSelectedValue,
      });
    },
    MultiSelectObject(value) {
      if (
        value[this.TextField] === "All" &&
        this.sortList.length !== this.multiSelectedObject.length
      ) {
        this.multiSelectedObject = this.sortList;
        this.$emit("multiselectedobject", {
          multiSelectedObject: this.multiSelectedObject,
        });
        return;
      } else if (
        value[this.TextField] === "All" &&
        this.sortList.length === this.multiSelectedObject.length
      ) {
        this.multiSelectedObject = [];
        this.$emit("multiselectedobject", {
          multiSelectedObject: this.multiSelectedObject,
        });
        return;
      } else if (
        value[this.TextField] !== "All" &&
        this.multiSelectedObject.some((e) => e[this.TextField] === "All")
      ) {
        this.multiSelectedObject = this.multiSelectedObject.filter(
          (item) => item[this.TextField] !== "All"
        );
        if (this.multiSelectedObject.length == 1) {
          this.multiSelectedObject = [];
          this.$emit("multiselectedobject", {
            multiSelectedObject: this.multiSelectedObject,
          });
          return;
        }
        this.$emit("multiselectedobject", {
          multiSelectedObject: this.multiSelectedObject,
        });
        return;
      }

      if (
        this.multiSelectedObject.some(
          (e) => e[this.TextField] === value[this.TextField]
        )
      ) {
        this.multiSelectedObject = this.multiSelectedObject.filter(
          (item) => item[this.TextField] !== value[this.TextField]
        );
      } else {
        if (
          this.maxAvailableChoice == undefined ||
          this.maxAvailableChoice > this.multiSelectedObject.length
        ) {
          this.multiSelectedObject.push(value);
          if (
            value[this.TextField] !== "All" &&
            this.multiSelectedObject.length === this.sortList.length - 1
          )
            this.multiSelectedObject = this.sortList;
        } else {
          alert(
            `No more than ${this.maxAvailableChoice} choices are allowed per product.`
          );
        }
      }

      this.$emit("multiselectedobject", {
        multiSelectedObject: this.multiSelectedObject,
      });
    },
  },
  beforeDestroy() {
    // Remove the click event listener when the component is destroyed
    document.removeEventListener("click", this.closeDropdown);
  },
};
</script>

<style lang="scss" scoped>
.drop_down_container {
  position: relative;
  @media screen and (max-width: 720px) {
    width: 100% !important;
  }
}
.input_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background: #fff;
  .input_filter {
    border: unset;
  }
  img {
    padding-left: 19px;
  }
}
</style>
