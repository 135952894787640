<template>
  <div class="baseSelect" v-click-outside="closeOptions">
    <!-- {{options}} -->
    <label class="baseSelect__label">
      <input
        type="text"
        class="baseSelect__label__input"
        :value="selectedOption ? selectedOption[pointer] : undefined"
        :placeholder="placeholder"
        @click="showOptions = !showOptions"
        readonly
      />
      <span
        class="material-icons baseSelect__label__dropIcon"
        :class="{ 'baseSelect__label__dropIcon--open': showOptions }"
      >
        expand_more
      </span>
    </label>
    <div
      class="baseSelect__options"
      :class="{ 'baseSelect__options--show': showOptions }"
    >
      <div
        class="baseSelect__options__option"
        :class="{
          'baseSelect__options__option--selected':
            selectedOption && selectedOption.id === option.id,
        }"
        v-for="option in options"
        :key="option.id"
        @click="selectAOption(option)"
      >
        {{ option[pointer] }}
        <span
          class="material-icons baseSelect__options__option__icon"
          v-if="selectedOption && selectedOption.id === option.id"
        >
          check
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selectOption"],
  props: {
    pointer: { type: String, default: "value" },
    options: { type: Array, required: true },
    selectedOption: { required: false },
    placeholder: { type: String, required: true },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  created() {},
  methods: {
    selectAOption(option) {
      this.$emit("selectOption", option);
      this.closeOptions();
    },
    closeOptions() {
      this.showOptions = false;
    },
    MultiSelectObject(value) {},
  },
};
</script>

<style lang="scss" scoped>
.baseSelect {
  width: 300px;
  position: relative;
  &__label {
    width: 100%;
    position: relative;
    padding: 9px 14px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    cursor: pointer;

    &__dropIcon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 7px;
      margin: auto;
      height: fit-content;
      transition: 0.3s;
      &--open {
        transform: rotate(180deg);
      }
    }
    &__input {
      pointer-events: none;
      font-size: 1.5rem;
      font-weight: 500;
      color: #008f4a;
      outline: none;
      width: 100%;
      &::placeholder {
        color: #b5b9c3;
      }
    }
  }
  &__options {
    width: 100%;
    background-color: white;

    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 20000;
    margin-top: 5px;
    max-height: 0;
    overflow-y: auto;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    &--show {
      max-height: 300px;
      opacity: 1;
      visibility: visible;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #addaaf;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #89b38b;
    }
    &__option {
      padding: 7px 9px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;
      &:hover {
        background: #edf7ee;
        cursor: pointer;
        color: #008f4a;
      }
      &--selected {
        background: #addaaf;
        &:hover {
          background: #addaaf;
          color: black;
        }
      }
      &__icon {
        color: #008f4a;
      }
    }
  }
}
</style>
